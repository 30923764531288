module.exports = [{
      plugin: require('/home/travis/build/merlindorin/website/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/travis/build/merlindorin/website/src/Layout.js"},
    },{
      plugin: require('/home/travis/build/merlindorin/website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139414063-1"},
    },{
      plugin: require('/home/travis/build/merlindorin/website/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/merlindorin/website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/home/travis/build/merlindorin/website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallbackWhitelist":[]},
    },{
      plugin: require('/home/travis/build/merlindorin/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
